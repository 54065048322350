import React from 'react';
import { Link } from "@react-navigation/web";
import {FormattedMessage, useIntl} from 'react-intl';
import interestRatesFromFile from "../InterestRates/components/InterestRates.json";
import axios from 'axios';
import ResultTable from "./components/ResultTable";
import { Formik, Field, Form, useField } from "formik";
import * as Yup from 'yup';
import WETDate from "../../components/WETDate";

//perpetuating the language querystring parameter, so french users will keep a consistent link
let langParam = {};
let urlParams = new URLSearchParams(document.location.search);
if (urlParams.has('lang')) {
	langParam = {lang: urlParams.get('lang')};
}

function Calculator({props, navigation}) {
	
	const [minDate, setMinDate] = React.useState("1986-10-01");
	const [maxDate, setMaxDate] = React.useState(new Date().toISOString().substring(0,10));
	const [interestRatesDownloadAttempted, setInterestRatesDownloadAttempted] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [totalInterest, setTotalInterest] = React.useState(calculateTotalInterest());
	const [isDisabled, setIsDisabled] = React.useState(false);
	const [defaultInterestType, setDefaultInterestType] = React.useState("DAILY_SPECIFIED_INTEREST");
	const [defaultPrincipal, setDefaultPrincipal] = React.useState("");
	const [defaultStartDate, setDefaultStartDate] = React.useState(undefined);
	const [defaultEndDate, setDefaultEndDate] = React.useState(undefined);
	const [cursorStyle, setCursorStyle] = React.useState({});
	
	const labels = {
		  "common.required"                  : useIntl().formatMessage({id:"common.required"})
		, "common.isRequired"                : useIntl().formatMessage({id:"common.isRequired"})
		, "common.mustBeAfter"               : useIntl().formatMessage({id:"common.mustBeAfter"})
		, "common.mustBeBefore"              : useIntl().formatMessage({id:"common.mustBeBefore"})
		, "common.mustBeAValidAmountOfMoney" : useIntl().formatMessage({id:"common.mustBeAValidAmountOfMoney"})
		, "common.mustBe12CharactersOrLess"  : useIntl().formatMessage({id:"common.mustBe12CharactersOrLess"})
		, "common.mustBeAValidDate"          : useIntl().formatMessage({id:"common.mustBeAValidDate"})
		, "calculator.startDate"             : useIntl().formatMessage({id:"calculator.startDate"})
		, "calculator.endDate"               : useIntl().formatMessage({id:"calculator.endDate"})
		, "http.networkError"                : useIntl().formatMessage({id:"http.networkError"})
	}
		
	if (!interestRatesDownloadAttempted) {
		let interestRates = interestRatesFromFile;
		
		//only try this once per state change
		axios.post(window.env["get-rates-url"]).then(function(response){
			//we got the data
			interestRates = response.data.interestRates;

			setMinDate(interestRates[interestRates.length-1]?.Effective_Date.substring(0,10)); 
			setMaxDate(interestRates[0]?.End_Date.substring(0,10));
			
		}).catch(function(error){
			//html response error received
			console.error("error from get request : " + error);
			// eslint-disable-next-line
			if (error == "Error: Network Error") {
				navigation.globalAlerts.setFlashError({duration:-1, messages:[labels["http.networkError"]]});
			}
			
		});
	    
		
		setInterestRatesDownloadAttempted(true);
	}
	
    
    function calculateInterest(interestType, startDate, endDate, principal, index) {
		let postData = {
			interestType: interestType,
			startDate: startDate,
			endDate: endDate,
			principal: principal.replaceAll("$", "").replaceAll(" ", "")
		}
		
		axios.post(window.env["calculate-interest-url"], postData).then(function(response){
			//we got the data
			postData.interest = parseFloat(response.data.result);
			
			let newData = data;
			newData[(index !== undefined ? index : data.length)] = postData;
			setData([...newData]);
			updateTotalInterest();
			
			if (index === undefined || index === data.length-1) {
				updateFormDefaults(postData);
			}
			
			setCursorStyle({});
			setIsDisabled(false);
		}).catch(function(error){
			//html response error received
			console.log("error from get request : " + error);
			setIsDisabled(false);
			return NaN;
		});
	}
    
    function updateFormDefaults(postData) {
		if (postData.interestType === "MONTHLY_PRESCRIBED_INTEREST") {
			setDefaultPrincipal(parseFloat(postData.principal).toFixed(2));
		} else {
			setDefaultPrincipal((parseFloat(postData.principal) + postData.interest).toFixed(2));
		}
		
		let newDate = new Date(postData.endDate+"T00:00:00");
		newDate.setDate(newDate.getDate() + 1); 

		setDefaultStartDate(newDate.toISOString().substring(0,10));
		setDefaultEndDate(newDate.toISOString().substring(0,10));
		setDefaultInterestType(postData.interestType);
    }

    function updateTotalInterest() {
    	setTotalInterest(calculateTotalInterest(data));
    }

    function clearDefaults() {
		setDefaultInterestType("DAILY_SPECIFIED_INTEREST");
		setDefaultPrincipal("");
		setDefaultStartDate(undefined);
		setDefaultEndDate(undefined);
    }
    
	return (
		<>
			<div className="grid-12" id="calculatorForm" style={cursorStyle}>
				<fieldset>
					<div className="form-group col-md-12">
						<label htmlFor="reference" className="control-label col-md-3"><FormattedMessage id="calculator.adjustmentNumber/reference"/></label>
						<div className="full-width">
							<div className="col-md-4">
								<input type="text" className="form-control full-width" maxLength="40" id="reference" name="reference" />
							</div>
							<div className="full-width noPrint">
								(<FormattedMessage id="calculator.adjustmentNumber.msg"/>)
							</div>
						</div>
					</div>
					<div className="noPrint">
						<h2><FormattedMessage id="calculator.calculate"/></h2>
						<Formik
							enableReinitialize={true}
							initialValues={{interestType:defaultInterestType, principal:defaultPrincipal, startDate:defaultStartDate, endDate:defaultEndDate}}
							validationSchema={Yup.object({
							       interestType: Yup.string().required(labels["common.isRequired"])
							       , principal: Yup.string().max(12, labels["common.mustBe12CharactersOrLess"]).matches(/^\$?\d+(?:\.\d{0,2})?$/, labels["common.mustBeAValidAmountOfMoney"]).required(labels["common.isRequired"])
							       , startDate: Yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, labels["common.mustBeAValidDate"]).required(labels["common.isRequired"])
							                  .test({
							                  	name: 'greaterThanMinDate',
							                  	exclusive: false,
							                  	params: {},
							                  	message: labels["common.mustBeAfter"] + " " + minDate,
							                  	test: function (value) {
							                  		return new Date(value) >= new Date(minDate);
							                  	},
							                  })
							                  .test({
							                  	name: 'lessThanMaxDate',
							                  	exclusive: false,
							                  	params: {},
							                  	message: labels["common.mustBeBefore"] + " " + maxDate,
							                  	test: function (value) {
							                  		return new Date(value) <= new Date(maxDate);
							                  	},
							                  })
							       , endDate: Yup.string()
							                  .matches(/^\d{4}-\d{2}-\d{2}$/, labels["common.mustBeAValidDate"])
							                  .required(labels["common.isRequired"])
							                  .test({
							                  	name: 'greaterThanStartDate',
							                  	exclusive: false,
							                  	params: {},
							                  	message: labels["common.mustBeAfter"]+' '+labels["calculator.startDate"],
							                  	test: function (value) {
							                  		let endDate = new Date(value);
							                  		let startDate = new Date(this.parent.startDate);
							                  		return endDate >= startDate;
							                  	},
							                  })
							                  .test({
							                  	name: 'lessThanMaxDate',
							                  	exclusive: false,
							                  	params: {},
							                  	message: labels["common.mustBeBefore"] + " " + maxDate,
							                  	test: function (value) {
							                  		return new Date(value) <= new Date(maxDate);
							                  	},
							                  })
							       ,
							})}
							onSubmit={async values => {
								setIsDisabled(true);
								setCursorStyle({cursor:"progress"});
								await new Promise((resolve,state) => {
									calculateInterest(values.interestType, values.startDate, values.endDate, values.principal); 
								})
							}}
						>
							<Form>
								<div className="form-group col-md-12">
									<label htmlFor="interestType" className="control-label col-md-3 required">
										<FormattedMessage id="calculator.interestType" /> <strong className="required">(<FormattedMessage id="common.required"/>)</strong>
									</label>
									<div className="col-md-4">
										<Field id="interestType" name="interestType" as="select" className="form-control full-width">
											<option value="DAILY_SPECIFIED_INTEREST">{useIntl().formatMessage({id:"calculator.interestType.options.0"})}</option>
											<option value="DAILY_PRESCRIBED_INTEREST">{useIntl().formatMessage({id:"calculator.interestType.options.1"})}</option>
											<option value="MONTHLY_PRESCRIBED_INTEREST">{useIntl().formatMessage({id:"calculator.interestType.options.2"})}</option>
										</Field>
									</div>
								</div>
								<NumberFieldWithLabel id="principal" name="principal" maxLength="12" cursorStyle={cursorStyle} label={useIntl().formatMessage({id:"calculator.principal"})}/>
								<DateFieldWithLabel id="startDate" name="startDate" cursorStyle={cursorStyle} label={useIntl().formatMessage({id:"calculator.startDate"})} min={"0000-01-01"} max={"9999-12-31"} />
								<DateFieldWithLabel id="endDate" name="endDate" cursorStyle={cursorStyle} label={useIntl().formatMessage({id:"calculator.endDate"})} min={"0000-01-01"} max={"9999-12-31"}/>
								
								<div className="form-group col-md-12">
									<button type="submit" className="btn btn-primary" disabled={isDisabled}><FormattedMessage id="button.submit" /></button>
									<button type="reset" onClick={()=>{clearDefaults()}} className="btn btn-default" style={{"marginLeft":20}}><FormattedMessage id="button.clearFields" /></button>
								</div>
							</Form>
						</Formik>
					</div>
				</fieldset>
				<ResultTable data={data} setData={(arr) => {setData(arr); updateTotalInterest();}} calculateInterest={calculateInterest} minDate={minDate} maxDate={maxDate} minDateFullYear={"0000-01-01"} maxDateFullYear={"9999-12-31"} totalInterest={totalInterest}/>
				<div className="offset-2 span-8" style={{"marginTop":20}}>
					<p className="noPrint">
						<Link routeName={"InterestRates"} params={langParam}>
							<FormattedMessage id="interestRates.title"/>
						</Link>
					</p>
					<p className="noPrint"><a href={useIntl().formatMessage({id:"calculator.aboutTheInterestCalcProgram.link"})}><FormattedMessage id="calculator.aboutTheInterestCalcProgram"/></a></p>
				</div>
			</div>
		</>
	);
}


function calculateTotalInterest(data) {
	let result = 0;
	if (!!data && Array.isArray(data) && data.length > 0) {
		for (var i in data) {
			if (!isNaN(data[i].interest)) {
				result += data[i].interest;
			}
		}
	}
	return result;
}

const DateFieldWithLabel = ({label, cursorStyle, ...props}) => {
	const meta = useField(props)[1];
	return (
		<div className="form-group col-md-12">
			<label htmlFor={props.id || props.name} className="control-label col-md-3 required" style={cursorStyle}>{label} <strong className="required">(<FormattedMessage id="common.required"/>)</strong></label>
			<div className={meta.touched && meta.error ? "col-md-4 has-error" : "col-md-4"}>
				<WETDate className="form-control full-width" name={props.name} min={props.min} max={props.max} />
			</div>
			{meta.touched && meta.error ? (
				<strong className="error"><span className="label label-danger"><span className="prefix"><FormattedMessage id="globalAlert.error"/><FormattedMessage id="wet.colon"/> </span>{meta.error}</span></strong>
			) : null}
		</div>
	);
};

const NumberFieldWithLabel = ({label, cursorStyle, ...props}) => {
	const meta = useField(props)[1];
	return (
		<div className="form-group col-md-12">
			<label htmlFor={props.id || props.name} className="control-label col-md-3 required" style={cursorStyle}>{label} <strong className="required">(<FormattedMessage id="common.required"/>)</strong></label>
			<div className="col-md-4">
				<Field id={props.id} name={props.name} type="text" className={meta.touched && meta.error ? " has-error form-control full-width error" : "form-control full-width"} maxLength="12"/>
			</div>
			{meta.touched && meta.error ? (
				<strong className="error"><span className="label label-danger"><span className="prefix"><FormattedMessage id="globalAlert.error"/><FormattedMessage id="wet.colon"/> </span>{meta.error}</span></strong>
			) : null}
		</div>
	);
};


Calculator.path = "";
Calculator.navigationOptions = {
	pageTitle: <FormattedMessage id="calculator.title"/>,
	breadcrumb: [],
	breadcrumbTitle: <FormattedMessage id="calculator.title"/>
};

export default Calculator;