import React from 'react';
import {FormattedMessage, FormattedNumber} from 'react-intl';


export default class WETTable extends React.Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			dom : props.dom ? props.dom : '<"top"i>rt<"bottom"p><"clear">'
			, pageLength : props.pageLength ? props.pageLength : 25	
			, className : props.className ? "wb-tables table "+props.className : "wb-tables table table-striped table-hover"
			, ordering : props.ordering !== undefined ? props.ordering : 'true'
		}
		
		this.renderData = this.renderData.bind(this);
		
		this.components = {
			FormattedMessage: FormattedMessage
			, FormattedNumber: FormattedNumber
		}
	}
	
	componentDidMount() {
		// Initialize the Tables plugin
		if (window.wb) {
			window.wb.add(".wb-tables");
		}
	}
	

	
	renderData(dataObj, columnIndex, rowIndex) {
		if (typeof dataObj === 'object' && !Array.isArray(dataObj) && dataObj.component in this.components) {
			return <td key={rowIndex+"_"+columnIndex}>{React.createElement(this.components[dataObj.component], dataObj.props, dataObj.children)}</td>;
		} else if (typeof dataObj === 'object' && !Array.isArray(dataObj)) {
			console.warn('Warning: WETTable.js input error.\nComponent type not found in whitelist so we are not rendering as a component.  Try adding the missing component to the variable, this.components, found within the constructor of WETTable.js');
			return (
				<td key={rowIndex+"_"+columnIndex}>{JSON.stringify(dataObj)}</td>
			);
		}
		return <td key={rowIndex+"_"+columnIndex}>{dataObj}</td>;
	}
	
	render() {
		return (
			<div className="data-table-wrapper">
				<table className={this.state.className} data-dom={this.state.dom} data-page-length={this.state.pageLength} data-ordering={this.state.ordering} ref={el => this.el = el}>
					<thead>
						<tr>
							{this.props.headers?.map(function(header,index){
								return (<th key={"header_"+index}>{header}</th>);
							})}
						</tr>
					</thead>
					<tbody>
						{(this.props.rows ? this.props.rows.map(function(row,rowIndex){
							return (
								<tr key={rowIndex}>{
										row.map((column, index) => this.renderData(column, index, rowIndex), this)
									}
								</tr>
							);
						}, this) : <tr></tr>)}
					</tbody>
				</table>
			</div>
		);
	}
}

