import React from 'react';
import {useIntl} from 'react-intl';
import DatePicker from 'react-date-picker/dist/entry.nostyle'	//needed for custom styling of the datepicker input field to match WET datepicker styling
import { useField } from "formik";

/**
 * A date input that matches the WET styling (excluding the calendar that pops-up), but also works with a React Formik form
 * Must be placed in a Formik Form context
 */
export default function WETDate(props) {

	const [className] = React.useState(props.className ? props.className + " wb-date-inited" : "form-control full-width wb-date-inited")
	const [name] = React.useState(!!props.name ? props.name : "")
	const [min] = React.useState(!!props.min ? new Date(props.min + "T00:00:00") : undefined)
	const [max] = React.useState(!!props.max ? new Date(props.max + "T00:00:00") : undefined)

	const [field, , { setValue }] = useField(name);	//used for accessing the field's initial value and used for setting date back into the FormikForm

	//the following is a hack to make up for the fact that this component doesn't (currently) have the ability to customize the "title" attributes on the buttons it generates
	React.useEffect(() => {
		//copying the aria-labels into the "title" attribute to meet accessibility standards
		const clearButtomElems = document.querySelectorAll("button.react-date-picker__clear-button");
		clearButtomElems.forEach((element) => {
			if (!element.title) {
				element.title = element.ariaLabel;
			}
		})
		const calendarButtonElems = document.querySelectorAll("button.react-date-picker__calendar-button");
		calendarButtonElems.forEach((element) => {
			if (!element.title) {
				element.title = element.ariaLabel;
			}
		})
	}, [])

	let onChange = dateStr => {
		if (dateStr && !isNaN(Date.parse(dateStr))) {
			let date = new Date(dateStr);
			let formikDateStr = date.toISOString().substring(0,10);
			setValue(formikDateStr);
		} else {
			setValue(undefined);
		}
	}

	return (
		<>
			<DatePicker 
				onChange={onChange} 
				value={field === undefined || field.value === undefined ? undefined : new Date(field.value+"T00:00:00")} 
				minDate={min}
				maxDate={max}
				locale={useIntl().formatMessage({id:"common.locale"})}
				dayPlaceholder={useIntl().formatMessage({id:"label.pattern.dayPortion"})}
				monthPlaceholder={useIntl().formatMessage({id:"label.pattern.monthPortion"})}
				yearPlaceholder={useIntl().formatMessage({id:"label.pattern.yearPortion"})}
				format="y-MM-dd"
				showLeadingZeros={false}
				className={className}
				wrapperClass={"input-group wb-date-wrap form-control wb-date-inited"}
				type="string"
				calendarIcon={<span className="react-date-picker-calendar-button"><span className="ui-datepicker-trigger btn btn-default"><span className="glyphicon glyphicon-calendar"></span></span></span>}
				calendarAriaLabel={useIntl().formatMessage({id:"button.title.toggleCalendar"})}
				clearAriaLabel={useIntl().formatMessage({id:"button.title.clearDate"})}
				openCalendarOnFocus={false}	//had issues with tabbing into date field causing this to pop-up automatically and add *a million* (sarcastic) tab presses to the tab order as it tabs through the calendar.
			/>
		</>
	);
}
