import React from 'react';
import LanguageSelection from './LanguageSelection.js';
import {FormattedMessage, useIntl} from 'react-intl';

function Banner(props){
	return (
		<div id="wb-bnr" className="container">
			<LanguageSelection />
			<div className="row">
				<div className="brand col-xs-5 col-md-4" property="publisher" typeof="GovernmentOrganization">
					<a href={("https://www.canada.ca/{lang}.html").replace("{lang}", document.documentElement.lang)} property="url"><img src={("./GCWeb/assets/sig-blk-{lang}.svg").replace("{lang}", document.documentElement.lang)} alt="" property="logo" key="governmentOfCanadaTopLogo"/><span className="wb-inv" property="name"> Government of Canada / <span lang="fr">Gouvernement du Canada</span></span></a>
					<meta property="areaServed" typeof="Country" content="Canada"/>
					<link property="logo" href="./GCWeb/assets/wmms-blk.svg"/>
				</div>
				<section id="wb-srch" className="col-lg-8 text-right">
					<h2><FormattedMessage id="wet.search"/></h2>
					<form action={("https://www.canada.ca/{lang}/sr.html").replace("{lang}", document.documentElement.lang)} method="get" name="cse-search-box" role="search" className="form-inline">
						<div className="form-group">
							<label htmlFor="wb-srch-q" className="wb-inv"><FormattedMessage id="wet.searchBar.searchCanada_ca"/></label>
							<input name="cdn" value="canada" type="hidden"/>
							<input name="st" value="s" type="hidden"/>
							<input name="num" value="10" type="hidden"/>
							<input name="langs" value={document.documentElement.lang} type="hidden"/>
							<input name="st1rt" value="1" type="hidden"/>
							<input name="s5bm3ts21rch" value="x" type="hidden"/>
							<input id="wb-srch-q" list="wb-srch-q-ac" className="wb-srch-q form-control" name="q" type="search" defaultValue="" size="34" maxLength="170" placeholder={useIntl().formatMessage({id:"wet.searchBar.searchCanada_ca"})}/>
							<input type="hidden" name="_charset_" value="UTF-8"/>
							<datalist id="wb-srch-q-ac">
							</datalist>
						</div>
						<div className="form-group submit">
							<button type="submit" id="wb-srch-sub" className="btn btn-primary btn-small" name="wb-srch-sub"><span className="glyphicon-search glyphicon"></span><span className="wb-inv"><FormattedMessage id="wet.search"/></span></button>
						</div>
					</form>
				</section>
			</div>
		</div>
	);
}

	
export default Banner;