import React from "react";
import {FormattedMessage, useIntl} from 'react-intl';
import { Formik, Form} from "formik";
import * as Yup from 'yup';
import DataTable from "./DataTable";

export default function ResultTable(props) {
	
	const labels = {
		  "common.required"                  : useIntl().formatMessage({id:"common.required"})
		, "common.isRequired"                : useIntl().formatMessage({id:"common.isRequired"})
		, "common.mustBeAfter"               : useIntl().formatMessage({id:"common.mustBeAfter"})
		, "common.mustBeBefore"              : useIntl().formatMessage({id:"common.mustBeBefore"})
		, "common.mustBeAValidAmountOfMoney" : useIntl().formatMessage({id:"common.mustBeAValidAmountOfMoney"})
		, "common.mustBe12CharactersOrLess"  : useIntl().formatMessage({id:"common.mustBe12CharactersOrLess"})
		, "common.mustBeAValidDate"          : useIntl().formatMessage({id:"common.mustBeAValidDate"})
		, "calculator.startDate"             : useIntl().formatMessage({id:"calculator.startDate"})
	}

	if (Array.isArray(props.data) && props.data.length > 0) {
		return (
		<>
			<Formik
				validateOnChange={true}
				initialValues={{data: props.data}}
				validationSchema={Yup.object().shape({
					data: Yup.array().of(
						Yup.object({
										interestType: Yup.string().required(labels["common.isRequired"])
										, principal: Yup.string().max(12, labels["common.mustBe12CharactersOrLess"]).matches(/^\$?\d+(?:\.\d{0,2})?$/, labels["common.mustBeAValidAmountOfMoney"]).required(labels["common.isRequired"])
									    , startDate: Yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, labels["common.mustBeAValidDate"]).required(labels["common.isRequired"])
						                  .test({
						                  	name: 'greaterThanMinDate',
						                  	exclusive: false,
						                  	params: {},
						                  	message: labels["common.mustBeAfter"] + " " + props.minDate,
						                  	test: function (value) {
						                  		return new Date(value) >= new Date(props.minDate);
						                  	},
						                  })
							              .test({
							              	name: 'lessThanMaxDate',
							              	exclusive: false,
							              	params: {},
							              	message: labels["common.mustBeBefore"] + " " + props.maxDate,
							              	test: function (value) {
							              		return new Date(value) <= new Date(props.maxDate);
							              	},
							              })
							       , endDate: Yup.string()
							                  .matches(/^\d{4}-\d{2}-\d{2}$/, labels["common.mustBeAValidDate"])
							                  .required(labels["common.isRequired"])
							                  .test({
							                  	name: 'greaterThanStartDate',
							                  	exclusive: false,
							                  	params: {},
							                  	message: labels["common.mustBeAfter"]+' '+labels["calculator.startDate"],
							                  	test: function (value) {
							                  		return new Date(value) >= new Date(this.parent.startDate);
							                  	},
							                  })
							                  .test({
							                  	name: 'lessThanMaxDate',
							                  	exclusive: false,
							                  	params: {},
							                  	message: labels["common.mustBeBefore"] + " " + props.maxDate,
							                  	test: function (value) {
							                  		return new Date(value) <= new Date(props.maxDate);
							                  	},
							                  })
							       ,
						})
					)
				})}
				enableReinitialize={true}
			>
			<Form>
				<fieldset style={{"marginTop":20, "borderColor":"black"}}>
					<div className="form-group col-md-12" style={{"marginTop":20}}>
						<DataTable data={props.data} setData={props.setData} calculateInterest={props.calculateInterest} minDate={props.minDate} maxDate={props.maxDate} minDateFullYear={props.minDateFullYear} maxDateFullYear={props.maxDateFullYear} totalInterest={props.totalInterest} />
					</div>
				</fieldset>
			</Form>
			</Formik>
			<button id="rtbutton" type="button" className="btn btn-default noPrint" style={{"marginLeft":20}} onClick={()=>{props.setData([]);}}><FormattedMessage id="button.clearTable" /></button>
		</>
		);
	}
	return <></>;
}
