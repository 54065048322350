import React, {useState} from 'react';
import { Link } from "@react-navigation/web";
import WETTable from '../../components/WETTable';
import {FormattedMessage, useIntl} from 'react-intl';
import interestRates from "./components/InterestRates.json";
import axios from 'axios';

//perpetuating the language querystring parameter, so french users will keep a consistent link
let langParam = {};
let urlParams = new URLSearchParams(document.location.search);
if (urlParams.has('lang')) {
	langParam = {lang: urlParams.get('lang')};
}

function InterestRates({props, navigation}) {
			
	const [stateInterestRates, setStateInterestRates] = useState(interestRates);
	const [interestRatesDownloaded, setInterestRatesDownloaded] = useState(false);

	let headers = [(<FormattedMessage id="interestRates.datatables.header.quarter"/>)
	              , (<FormattedMessage id="interestRates.datatables.header.prescribedInterestRate"/>)
	              , (<FormattedMessage id="interestRates.datatables.header.specifiedInterestRate"/>)];


	let months = [];
	months[0] = useIntl().formatMessage({id:"common.months.0"});
	months[1] = useIntl().formatMessage({id:"common.months.1"});
	months[2] = useIntl().formatMessage({id:"common.months.2"});
	months[3] = useIntl().formatMessage({id:"common.months.3"});
	months[4] = useIntl().formatMessage({id:"common.months.4"});
	months[5] = useIntl().formatMessage({id:"common.months.5"});
	months[6] = useIntl().formatMessage({id:"common.months.6"});
	months[7] = useIntl().formatMessage({id:"common.months.7"});
	months[8] = useIntl().formatMessage({id:"common.months.8"});
	months[9] = useIntl().formatMessage({id:"common.months.9"});
	months[10] = useIntl().formatMessage({id:"common.months.10"});
	months[11] = useIntl().formatMessage({id:"common.months.11"});
		
	const labels = {
		"http.networkError"                : useIntl().formatMessage({id:"http.networkError"})
	}
	
	let generateRatesTables = () => {
		//iterate through data to generate subtables

		
		if (!interestRatesDownloaded) {
			axios.post(window.env["get-rates-url"]).then(function(response){
				//we got the data
				setStateInterestRates(response.data.interestRates);
				setInterestRatesDownloaded(true);
			}).catch(function(error){
				//html response error received
				console.log("error from get request : " + error);
				// eslint-disable-next-line
				if (error == "Error: Network Error") {
					navigation.globalAlerts.setFlashError({duration:-1, messages:[labels["http.networkError"]]});
				}
			});
		}

		console.log(stateInterestRates);
		
		let organizedData = {};
		stateInterestRates.forEach(function (dataRow, index) {
			let currentRowYear = dataRow["Quarter"].substring(0,4);
			if (!organizedData[currentRowYear]) {
				organizedData[currentRowYear] = []
			}
			organizedData[currentRowYear].unshift([getQuarterStr(dataRow), getFormattedNumberObj(dataRow["Prescribed_Interest_Rate"]), getFormattedNumberObj(dataRow["Specified_Interest_Rate"])]);
		});

		
		return Object.keys(organizedData).reverse().map((key) =>
			<div key={"interestRatesTable-"+key}>
				<h3>{key}</h3>
				<WETTable headers={headers} rows={organizedData[key].reverse()} dom='<"top">t<"bottom"p><"clear">' ordering='false' />
			</div>
		)
	}


	function getQuarterStr(dataRow) {
		let effectiveDateStr = dataRow["Effective_Date"];
		if (effectiveDateStr.indexOf("T00:00:00") === -1) {
			effectiveDateStr += "T00:00:00";
		}
		let endeDateStr = dataRow["End_Date"];
		if (endeDateStr.indexOf("T00:00:00") === -1) {
			endeDateStr += "T00:00:00";
		}
		let effectiveDate = new Date(effectiveDateStr);
		let endDate = new Date(endeDateStr);
		
		return months[effectiveDate.getMonth()] + " " + effectiveDate.getDate() + " - " + months[endDate.getMonth()] + " " + endDate.getDate();
	}
	
	function getFormattedNumberObj(value) {
		return {component:"FormattedNumber", props:{value:value, minimumFractionDigits: "4"}};
	}

	return (
		<section>
			<p><FormattedMessage id="interestRates.introParagraph"/></p>
			<p>
				<FormattedMessage id="interestRates.introParagraphLink"/>&nbsp;<Link routeName="Calculator" params={langParam}><FormattedMessage id="calculator.title"/></Link>.
			</p>
			{generateRatesTables()}
		</section>
	);
}



InterestRates.path = ""; //irt-tti
InterestRates.navigationOptions = {
	pageTitle: <FormattedMessage id="interestRates.title"/>,
	breadcrumb: [<Link routeName="Calculator" params={langParam}><FormattedMessage id="calculator.title"/></Link>],
	breadcrumbTitle: <FormattedMessage id="interestRates.title"/>,
};

export default InterestRates;