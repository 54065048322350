import React from "react";
import {FormattedMessage, FormattedNumber} from 'react-intl';
import { useFormikContext } from "formik";
import DataRow from "./DataRow";

export default function DataTable(props) {
	//needed to split out the table here to get access to the "values" attribute from the FormikContext, to build the read-only values that mirror the form fields
	const { values } = useFormikContext();
	
	return (
		<table className="table table-striped table-hover calculationResult">
			<thead>
				<tr>
					<th style={{"width":216}}><FormattedMessage id="calculator.interestType" /></th>
					<th style={{"width":195}}><FormattedMessage id="calculator.startDate" /></th>
					<th style={{"width":195}}><FormattedMessage id="calculator.endDate" /></th>
					<th style={{"width":169}}><FormattedMessage id="calculator.principal" /></th>
					<th style={{"width":74}}><FormattedMessage id="calculator.interest" /></th>
					<th style={{"width":80}} className="buttonField"></th>
				</tr>
			</thead>
			<tbody>
				{values.data.map((row, index) => {
					return (
						<DataRow data={props.data} row={props.data[index]} index={index} setData={props.setData} key={"DataRow."+index} calculateInterest={props.calculateInterest} minDate={props.minDate} maxDate={props.maxDate} minDateFullYear={props.minDateFullYear} maxDateFullYear={props.maxDateFullYear} />
					);
				})}
			</tbody>
			<tfoot>
				<tr>
					<td colSpan="4"><FormattedMessage id="calculator.totalInterest"/></td>
					<td>{!!props.totalInterest && typeof props.totalInterest == 'number' ? <FormattedMessage id="common.money" values={{amount: <FormattedNumber value={props.totalInterest.toFixed(2)} minimumFractionDigits="2" />}}/> : ""}</td>
				</tr>
			</tfoot>
		</table>
	);
}

