import React from 'react';
import {FormattedMessage} from 'react-intl';

/** 
 * Explanation of why the language link reloads the page with a query parameter instead of a more
 * "React" like approach of just refreshing the labels of the components:
 * 
 * The WET plugin and the JQuery DataTables plugin (ran by WET) both create elements on the DOM
 * that contain translated language labels.  These newly created elements aren't recognized by React
 * so they don't get translated when React switches the language.  Since these plugins are designed to
 * run once when the page loads, the easiest and most reliable way to ensure that the correct language
 * is being displayed is to reload the page.
 */
function LanguageSelection(props) {
	
	return (
		<>
			<section id="wb-lng" className="text-right">
				<h2 className="wb-inv"><FormattedMessage id="wet.languageSelection" /></h2>
				<ul className="list-inline margin-bottom-none">
					<li><a id="langSwitchToggle" lang={document.documentElement.lang === "en" ? "fr" : "en"} href={document.documentElement.lang === "en" ? "?lang=fr_CA" : "?lang=en_CA"}>
						<FormattedMessage id="wet.alternateLanguage" />
					</a></li>
				</ul>
			</section>
		</>
	);
}

export default LanguageSelection;