import React from 'react';
//navigation
import {
  createNavigator,
  SwitchRouter,
  SceneView
} from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import {scenes} from './scenes/Scenes'

//Internationalization and Localization
import {IntlProvider} from 'react-intl';
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

//Template Components
import Header from './templates/Header';
import GlobalAlerts from './templates/GlobalAlerts';
import DateModified from './templates/DateModified';
import Footer from './templates/Footer';

import './App.css';


export class TemplateView extends React.Component {
	constructor(props) {
		super(props);
			
		this.state = {
			versionNumber : props.version
			, dateModified : !!props.dateModified ? props.dateModified : Date.now()
			, messages : {'en': messages_en, 'fr': messages_fr}
			, flashSuccess : null
			, flashNotice : null
			, flashWarning : null
			, flashError : null
			, globalAlertDuration : 3000
		}
		
		this.setFlashSuccess = this.setFlashSuccess.bind(this);
		this.setFlashNotice = this.setFlashNotice.bind(this);
		this.setFlashWarning = this.setFlashWarning.bind(this);
		this.setFlashError = this.setFlashError.bind(this);
	}
	
	flashSuccessAnimationTimerID = undefined;
	flashNoticeAnimationTimerID = undefined;
	flashWarningAnimationTimerID = undefined;
	flashErrorAnimationTimerID = undefined;
	startTime = {};
	currentPageIndex = -1;
	
	setFlashSuccess(messages) {
		this.setFlashMessages(messages, this.flashSuccessAnimationTimerID, "flashSuccess", this.setFlashSuccess, "successAlertTimerBar");
	}
	
	setFlashNotice(messages) {
		this.setFlashMessages(messages, this.flashNoticeAnimationTimerID, "flashNotice", this.setFlashNotice, "noticeAlertTimerBar");
	}
	
	setFlashWarning(messages) {
		this.setFlashMessages(messages, this.flashWarningAnimationTimerID, "flashWarning", this.setFlashWarning, "warningAlertTimerBar");
	}
	
	setFlashError(messages) {
		this.setFlashMessages(messages, this.flashErrorAnimationTimerID, "flashError", this.setFlashError, "errorAlertTimerBar");
	}
	
	setFlashMessages(messages, animationTimerID, stateKey, thisSetFlash, timerBarElementID) {
		if (animationTimerID) {
			clearInterval(animationTimerID)
		}
		let msgObj = this.getFlashMsgObj(messages)
		this.setState({[stateKey] : msgObj});
		this.startTime[stateKey] = Date.now();
		animationTimerID = setInterval( 
			() => this.updateCSS(this.state[stateKey]
					, thisSetFlash
					, stateKey
					, animationTimerID
					, timerBarElementID
					, msgObj)
			, 1
		)
	}	
	
	getFlashMsgObj(messages) {
		if (messages === null || messages === undefined) {
			return messages;
		}
		
		//inserting singular message into an array if it's not already in an array
		if (messages instanceof Array) {
			return {messages: messages};
		}
		if (!!messages.messages) {
			return messages;
		}
		return {messages: [messages]};
	}
	
	updateCSS(thisStateFlash, thisSetFlash, stateKey, thisFlashAnimationTimerID, timerBarElementID, msgObj) {
		if (thisStateFlash) {
			let duration = !!msgObj?.duration ? msgObj.duration : this.state.globalAlertDuration * (msgObj?.messages?.length ?? 1);
			if (duration > 0) {
				this.incrementTimerBar(stateKey, thisFlashAnimationTimerID, timerBarElementID, duration, thisSetFlash);
			} else {
				if (document.getElementById(timerBarElementID)) {
					document.getElementById(timerBarElementID).style.width = '0%';
				}
				clearInterval(thisFlashAnimationTimerID);
			}
		} 
		else {
			clearInterval(thisFlashAnimationTimerID);
		}
	}

	incrementTimerBar(stateKey, thisFlashAnimationTimerID, timerBarElementID, duration, thisSetFlash) {
		let nextPercent = (Date.now() - this.startTime[stateKey]) / duration * 100;
		if (nextPercent > 100) {
			nextPercent = 100;
		}
		
		if (document.getElementById(timerBarElementID)) {
			document.getElementById(timerBarElementID).style.width = nextPercent+'%';
		}
		if (nextPercent === 100) {
			clearInterval(thisFlashAnimationTimerID);
			setTimeout(() => this.autoUnmountFlashMessage(thisSetFlash, stateKey), 100);
		}
	}
	
	autoUnmountFlashMessage(thisSetFlash, stateKey) {
		thisSetFlash(null);
		this.startTime[stateKey] = null;
	}
	
	componentDidUpdate() {
		//checking if the page has changed and clearing out any global alerts from the previous page render 
		const { navigation } = this.props;
		if (this.currentPageIndex !== navigation?.state.index ?? this.currentPageIndex) {
			
			this.setState({flashSuccess : null});
			this.setState({flashNotice : null});
			this.setState({flashWarning : null});
			this.setState({flashError : null});
			
			this.currentPageIndex = navigation?.state.index;
		}
	}

	render() {
		const { descriptors, navigation } = this.props;
		const activeKey = navigation?.state.routes[navigation?.state.index].key;
		const descriptor = (!!descriptors && !!activeKey ? descriptors[activeKey] : {navigation: {}, options: {}, getComponent: ()=>{return "Calculator";}});
		descriptor.navigation.globalAlerts = {
			setFlashSuccess : this.setFlashSuccess
			, setFlashNotice : this.setFlashNotice
			, setFlashWarning : this.setFlashWarning
			, setFlashError : this.setFlashError
		};
		return (
			<IntlProvider locale={document.documentElement.lang} messages={this.state.messages[document.documentElement.lang]}>
				<Header
					pageTitle={descriptor.options.pageTitle}
					breadcrumb={descriptor.options.breadcrumb}
					breadcrumbTitle={descriptor.options.breadcrumbTitle}
					currentMenuItem={activeKey}
				/>
				<main className="container" typeof="WebPageElement" property="mainContentOfPage">
					<GlobalAlerts flashSuccess={this.state.flashSuccess} flashNotice={this.state.flashNotice} flashWarning={this.state.flashWarning} flashError={this.state.flashError}
						setFlashSuccess={this.setFlashSuccess} setFlashNotice={this.setFlashNotice} setFlashWarning={this.setFlashWarning} setFlashError={this.setFlashError}
						/>
					{(descriptor.options.pageTitle !== "" && descriptor.options.pageTitle !== undefined  ? (<h1 id="wb-cont" property="name">{descriptor.options.pageTitle}</h1>) : "")}
					<SceneView
						component={descriptor.getComponent()}
						navigation={descriptor.navigation}
					/>
					<DateModified versionNumber={this.state.versionNumber} dateModified={this.state.dateModified} />
				</main>
				<Footer />
			</IntlProvider>
		);
	}
}

//for more info on how this works, see: https://reactnavigation.org/docs/3.x/web-support/
const AppNavigator = createNavigator(
	TemplateView,
	SwitchRouter(scenes),
	{}
);

const App = createBrowserApp(AppNavigator);

export default App;
