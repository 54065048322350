import React, {useState} from "react";
import {FormattedMessage, useIntl, FormattedNumber} from 'react-intl';
import { Field, useField, useFormikContext } from "formik";
import WETDate from "../../../components/WETDate";

export default function DataRow(props) {
	let options = {
								"DAILY_SPECIFIED_INTEREST": useIntl().formatMessage({id:"calculator.interestType.options.0"}),
								"DAILY_PRESCRIBED_INTEREST": useIntl().formatMessage({id:"calculator.interestType.options.1"}),
								"MONTHLY_PRESCRIBED_INTEREST": useIntl().formatMessage({id:"calculator.interestType.options.2"})
							};
	const labels = {
		  "calculator.startDate"                  : useIntl().formatMessage({id:"calculator.startDate"})
		, "calculator.endDate"                : useIntl().formatMessage({id:"calculator.endDate"})
		, "calculator.principal"               : useIntl().formatMessage({id:"calculator.principal"})
		, "button.save" : useIntl().formatMessage({id:"button.save"})
		, "button.edit" : useIntl().formatMessage({id:"button.edit"})
		, "button.delete" : useIntl().formatMessage({id:"button.delete"})
	}

	const [editToggle, setEditToggle] = useState(false);
	const { values, validateForm } = useFormikContext();

	function saveEdit(index) {
		validateForm().then((errors) => {
			if (Object.keys(errors).length === 0 && errors.constructor === Object) {
				props.data[index].interest = undefined;
	
				//recalculate here
				props.calculateInterest(values.data[index].interestType, values.data[index].startDate, values.data[index].endDate, values.data[index].principal, index);
				
				setEditToggle(false);
			}
		});
		
	}

	function deleteRow(index) {
		let newData = props.data;
		newData.splice(index, 1);
		props.setData([...newData]);
	}
	
	return (
		<tr id={"row."+props.index} key={"resultTable.row."+props.index}>
			<td className="textField">
				<div id="field.0" className="full-width">
					{editToggle 
						?
							<Field id={'data['+props.index+'].interestType'} name={'data['+props.index+'].interestType'} as="select" className="form-control full-width">
								<option value="DAILY_SPECIFIED_INTEREST">{options["DAILY_SPECIFIED_INTEREST"]}</option>
								<option value="DAILY_PRESCRIBED_INTEREST">{options["DAILY_PRESCRIBED_INTEREST"]}</option>
								<option value="MONTHLY_PRESCRIBED_INTEREST">{options["MONTHLY_PRESCRIBED_INTEREST"]}</option>
							</Field>
						: options[props.row?.interestType]}
				</div>
			</td>
			<td className="dateField">
				<div id="field.1.data" className="full-width">
					{editToggle
						? <DateField id={'data['+props.index+'].startDate'} name={'data['+props.index+'].startDate'} label={labels["calculator.startDate"]} min={props.minDateFullYear} max={props.maxDateFullYear}/>
						: props.row?.startDate
					}
				</div>
			</td>
			<td className="dateField">
				<div id="field.2.data" className="full-width">
					{editToggle
						? <DateField id={'data['+props.index+'].endDate'} name={'data['+props.index+'].endDate'} label={labels["calculator.endDate"]} min={props.minDateFullYear} max={props.maxDateFullYear}/>
						: props.row?.endDate
					}
				</div>
			</td>
			<td className="numberField">
				<div id="field.3.data" className="full-width">
					{editToggle
						? <NumberField id={'data['+props.index+'].principal'} name={'data['+props.index+'].principal'} maxLength="12" label={labels["calculator.principal"]}/>
						: <FormattedMessage id="common.money" values={{amount: <FormattedNumber value={props.row?.principal} minimumFractionDigits="2" />}}/>
					}
				</div>
			</td>
			<td className="numberField">
				{(props.data !== undefined && props.data[props.index]?.interest !== undefined) ? <FormattedMessage id="common.money" values={{amount: <FormattedNumber value={props.data[props.index].interest.toFixed(2)} minimumFractionDigits="2" />}}/> : ""}
			</td>
			<td className="buttonField">
				{editToggle
					? <button type="button" title={labels["button.save"]} onClick={() => saveEdit(props.index)}><span className="glyphicon glyphicon-check"></span></button> 
					: <>
					    <button type="button" title={labels["button.edit"]} onClick={() => setEditToggle(true)} style={{"marginLeft":2, "marginRight":2}}><span className="glyphicon glyphicon-edit"></span></button>
					    <button type="button" title={labels["button.delete"]} onClick={() => deleteRow(props.index)} style={{"marginLeft":2, "marginRight":2}}><span className="glyphicon glyphicon-trash"></span></button>
					  </>
				}
				
			</td>
		</tr>
	);
}

const DateField = ({label, ...props}) => {
	const [meta] = useField(props);
	return (
		<>
			<div className={meta.error ? "full-width has-error" : "full-width"}>
				<WETDate className="form-control full-width" name={props.name} min={props.min} max={props.max} />
			</div>
			{meta.error ? 
				<strong className="error"><span className="label label-danger"><span className="prefix"><FormattedMessage id="globalAlert.error"/><FormattedMessage id="wet.colon"/> </span>{meta.error}</span></strong>
			: null}
		</>
	);
};

const NumberField = ({label, ...props}) => {
	const meta = useField(props)[1];
	return (
		<>
			<Field id={props.id} name={props.name} type="text" className={meta.touched && meta.error ? " has-error form-control full-width error" : "form-control full-width"} maxLength="12"/>
			{meta.touched && meta.error ? 
				<strong className="error"><span className="label label-danger"><span className="prefix"><FormattedMessage id="globalAlert.error"/><FormattedMessage id="wet.colon"/> </span>{meta.error}</span></strong>
			: null}
		</>
	);
};

