import React from 'react';
import {FormattedMessage} from 'react-intl';


function BreadCrumbs(props) {
	
	return (
		<nav role="navigation" id="wb-bc" property="breadcrumb">
			<h2><FormattedMessage id="wet.youAreHere"/></h2>
			<div className="container">
				<div className="row">
					<ol className="breadcrumb">
						{(props.breadcrumb ? props.breadcrumb.map(function (item,index){return <li key={"breadcrumb"+index}>{item}</li>}) : "")}
						<li>{(props.breadcrumbTitle ? props.breadcrumbTitle : props.pageTitle)}</li>
					</ol>
				</div>
			</div>
		</nav>
	);
}

export default BreadCrumbs;