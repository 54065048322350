//Import of pages in the app
import Calculator from './Calculator/index';
import InterestRates from './InterestRates/index';

/**
 * All of the scenes (pages) in the app (or any app you want to make a <Link/> to)
 * for use by react-navigation/core > SwitchRouter 
 */
export const scenes = {
	Calculator
	, InterestRates
};