import React from "react";
import {FormattedMessage} from 'react-intl';


function GlobalAlerts(props) {
	
	const renderAlert = (type, prop, setProp, headerMessage, mouseEnterId, alertClassName, timerBarId) => {
		
		return <div id={mouseEnterId} onMouseEnter={() => {
							let msgObj=prop; 
							msgObj.duration=-1; 
							setProp(msgObj)
						}}>
					<section className={"alert "+alertClassName}>
						<h3>
							{headerMessage}!
							{renderAlertClose(setProp, prop?.duration ?? 0)}
						</h3>
						{prop.messages.map(function(msg, index) {
							return (
								<div key={type+"Msg-"+index}>{msg}</div>
							);
						})}
					</section>
					<span id={timerBarId} className={"alert "+alertClassName+" alertTimerBar"} style={{width:'0%'}}></span>
				</div>
	}
	
	const renderAlertClose = (setFlashMsgFunction, duration) => {
		if (setFlashMsgFunction && duration < 0) {
			return <button className="mfp-close" id="globalAlertClose" type="button" onClick={()=>(setFlashMsgFunction(null))}>
					×
					</button>
		}
		return <></>;
	}
	
	if (props.flashSuccess?.messages || props.flashNotice?.messages || props.flashWarning?.messages || props.flashError?.messages) {
		return (
			<div className="row">
				<section className="global-alert">
					{props.flashSuccess ? renderAlert("success", props.flashSuccess, props.setFlashSuccess, <FormattedMessage id="globalAlert.success" />, "mouse-enter-s", "alert-success", "successAlertTimerBar") : ""}
					{props.flashNotice ? renderAlert("notice", props.flashNotice, props.setFlashNotice, <FormattedMessage id="globalAlert.notice" />, "mouse-enter-n", "alert-info", "noticeAlertTimerBar") : ""}
					{props.flashWarning ? renderAlert("warning", props.flashWarning, props.setFlashWarning, <FormattedMessage id="globalAlert.warning" />, "mouse-enter-w", "alert-warning", "warningAlertTimerBar") : ""}
					{props.flashError ? renderAlert("error", props.flashError, props.setFlashError, <FormattedMessage id="globalAlert.error" />, "mouse-enter-e", "alert-danger", "errorAlertTimerBar") : ""}
				</section>
			</div>
		);
	}
	return <></>;
}

export default GlobalAlerts;